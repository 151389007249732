<template lang="pug">
  section.charity
    .charity__content.container.is-max-fullhd
      .charity__wrapper.is-flex
        .charity__left-block
          v-image.lazy.charity__image(src="homePage/charity/children@2x.jpg", :alt="$t('homePage.charity.alt')")
        .charity__right-block
          .charity__right-wrapper.is-flex.is-flex-direction-column
            icon.charity__icon(iconName="charity", iconColor="#80704d", width="75", height="75")
            .charity__subtitle {{$t('homePage.charity.subtitle')}}
            .charity__title {{$t('homePage.charity.title')}}
            .charity__description {{$t('homePage.charity.description')}}
            v-link.charity__link.is-underline(to="https://www.thenaturalsapphirecompany.com/education/about-us/nsc-charity-work/", type="native", target="_blank", no-follow) {{ $t('homePage.charity.link') }}


</template>

<script>
export default {
  name: 'CharityComponent'
}
</script>

<style scoped lang="sass">
.charity
  background-color: #F1EFE8
  &__wrapper
    padding: 132px 0
    gap: 54px
    +touch
      padding: 80px 0
      gap: 20px
    +mobile
      flex-direction: column
      padding: 60px 0
      gap: 40px
  &__image
    max-width: 771px
    width: 100%
    height: 100%
    ::v-deep
      picture
        height: 100%
      img
        height: 100%
        object-fit: cover
        aspect-ratio: 36 / 22
        +touch
          aspect-ratio: 59 / 78
        +mobile
          width: 100%
          height: 468px
          aspect-ratio: initial
  &__left-block,
  &__right-block
    width: 50%
    +mobile
      width: 100%
  &__right-wrapper
    max-width: 505px
    width: 100%
    text-align: center
    +mobile
      margin: 0 auto
  &__icon
    margin: 0 auto 30px

  &__subtitle
    font-size: 12px
    line-height: 24px
    text-transform: uppercase
    margin-bottom: 20px
  &__title
    font-family: $lora
    font-size: 36px
    line-height: 42px
    font-style: italic
    max-width: 391px
    width: 100%
    margin: 0 auto 30px
    +touch
      font-size: 32px
      line-height: 42px
  &__description
    font-size: 16px
    line-height: 24px
    margin-bottom: 40px
  &__link
    font-weight: bold
    font-size: 16px
    line-height: 24px
    cursor: pointer
    transition: color 0.3s
</style>
