import { render, staticRenderFns } from "./Charity.vue?vue&type=template&id=819b3940&scoped=true&lang=pug&"
import script from "./Charity.vue?vue&type=script&lang=js&"
export * from "./Charity.vue?vue&type=script&lang=js&"
import style0 from "./Charity.vue?vue&type=style&index=0&id=819b3940&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "819b3940",
  null
  
)

export default component.exports